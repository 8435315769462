//import './global.css';

import Typography from "typography"
import OceanBeach from "typography-theme-ocean-beach"

OceanBeach.overrideThemeStyles = ({ rhythm }, options) => ({
  "a.gatsby-resp-image-link": {
    boxShadow: "none",
  },
  "pre[class*='language-']": {
    marginTop: "0 !important",
    marginBottom: rhythm(1) + " !important",
  },
  "h1": {
    fontSize: "1.9em",
  }
})

delete OceanBeach.googleFonts

const typography = new Typography(OceanBeach)

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
