import React from "react"
import { Link } from "gatsby"

import { rhythm, scale } from "../utils/typography"

class Layout extends React.Component {
  render() {
    const { location, title, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    let header

    if (location.pathname === rootPath) {
      header = (
        <h1
          style={{
            ...scale(0.75),
            marginBottom: rhythm(1.25),
            marginTop: 0,
          }}
        >
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `inherit`,
            }}
            to={`/`}
          >
            {title}
          </Link>
        </h1>
      )
    } else {
      header = (
        <h3
          style={{
            marginTop: 0,
            color: '#950451',
          }}
        >
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `inherit`,
            }}
            to={`/`}
          >
            {title}
          </Link>
        </h3>
      )
    }
    return (
      <div
        style={{
          marginLeft: `auto`,
          marginRight: `auto`,
          maxWidth: rhythm(24),
          padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
        }}
      >
        <header>{header}</header>
        <main>{children}</main>
        <footer>
          <a
            href="https://mobile.twitter.com/Daniel1618033"
            target="_blank"
            rel="noopener noreferrer"
          >
            twitter
          </a>{" "}
          &bull;{" "}
          <a
            href="https://github.com/dnlr"
            target="_blank"
            rel="noopener noreferrer"
          >
            github
          </a>{" "}
          &bull;{" "}
          <a
            href="https://stackoverflow.com/users/1384646/daniel-romero?tab=profile"
            target="_blank"
            rel="noopener noreferrer"
          >
            stack overflow
          </a>{" "}
          &bull;{" "}
          <a href="mailto:romerodaniel@protonmail.com">email</a>
        </footer>
      </div>
    )
  }
}

export default Layout
